import React, { useEffect, useState } from 'react';
import { LazyRender } from 'reaxl';

const LazyComponent = ({
    children,
    fallback = null,
    threshold,
    renderOnScroll = true,
}) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return isClient ? (
        <>
            {renderOnScroll ? (
                <LazyRender
                    fallback={fallback}
                    threshold={threshold}
                >
                    {children}
                </LazyRender>
            ) : (<>{children}</>)}
        </>
    ) : (
        <>{fallback}</>
    );
};

export default LazyComponent;
